@font-face {
  font-family: “San Francisco”;
  font-weight: 400;
  src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-regular-webfont.woff 304");
}

html, body {
  color: #ccc;
  background-color: #444;
  margin: 0;
  padding: 0;
  font-family: San Francisco, Helvetica Neue, Helvetica, Arial, sans-serif;
}

html a, body a {
  color: #ccc;
}

.header {
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 800px;
  height: 20px;
  margin: 0 auto;
  display: flex;
}

.header div {
  flex-grow: 1;
  width: 33%;
}

.header .header-title {
  text-align: left;
}

.header .header-title span {
  margin-left: 10px;
}

.header .header-title h1 {
  margin: 0;
  padding-left: 10px;
  font-size: 100%;
}

.header .header-title h1 a {
  text-decoration: none;
}

.header .header-title h1 a:hover {
  text-decoration: underline;
}

.header .header-link {
  text-align: center;
}

.header .header-extra {
  text-align: right;
}

.header .header-extra span {
  padding-right: 10px;
}

.header a, .header a:visited {
  color: #ccc;
  font-weight: bold;
}

.weather-container {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.sensor-loading-container {
  text-align: center;
}

.sensor-reading-container {
  text-align: center;
  min-width: 200px;
  max-width: 300px;
  padding: 40px 40px 10px;
}

.sensor-reading-container h2 {
  margin: 0;
}

.sensor-reading-container h2 a {
  color: #ccc;
  text-decoration: none;
}

.sensor-reading-container .temp {
  font-size: 300%;
  font-weight: bold;
}

.sensor-reading-container .feelslike {
  font-size: 125%;
}

.sensor-reading-container .humidity {
  font-size: 150%;
}

.sensor-reading-container .dailyrain {
  font-size: 115%;
}

.sensor-reading-container .lastupdate {
  font-size: 85%;
}

.sensor-list-container {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

.sensor-list-item {
  justify-content: space-between;
  align-items: start;
  margin: 20px;
  display: flex;
}

.sensor-list-item .sensor-information {
  padding-right: 5px;
}

.sensor-list-item .sensor-information .sensor-title h2 {
  margin-top: 0;
  margin-bottom: 4px;
}

.sensor-list-item .sensor-information .sensor-title h2 a, .sensor-list-item .sensor-information .sensor-title h2 a:visited {
  color: #ccc;
}

.sensor-list-item .sensor-information .sensor-details, .sensor-list-item .sensor-information .sensor-owner {
  font-size: 95%;
}

.sensor-list-item .sensor-reading {
  text-align: right;
}

.sensor-list-item .sensor-reading .temp {
  font-size: 28px;
  font-weight: bold;
}

.sensor-list-item .sensor-reading .sensor-action .toggle-checkbox {
  justify-content: end;
  margin-top: 10px;
  display: flex;
}

.sensor-list-pagination {
  text-align: center;
  padding-bottom: 20px;
  line-height: 150%;
}

.sensor-detail-container {
  max-width: 1200px;
  margin: auto;
}

.sensor-detail-container .toggle-container {
  float: right;
  margin-right: 20px;
}

.sensor-detail-container .top-containers {
  flex-direction: row;
  justify-content: center;
  display: flex;
}

.sensor-detail-container .top-containers .top-left-container {
  margin: auto 0;
  padding-right: 25px;
}

.sensor-detail-container .bottom-containers {
  flex-direction: row;
  justify-content: center;
  padding-top: 10px;
  display: flex;
}

.sensor-detail-container .bottom-containers .bottom-left-container {
  margin: auto 0;
}

.sensor-detail-container .battery-container {
  flex-direction: row;
  justify-content: center;
  padding-top: 10px;
  font-size: 16px;
  display: flex;
}

.sensor-detail-container .genai-container {
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
  padding-top: 10px;
  padding-bottom: 14px;
}

.sensor-detail-container .genai-container .genai-content {
  white-space: pre-wrap;
  max-width: 400px;
  margin: 0 auto;
  font-size: 18px;
  font-weight: bold;
}

.sensor-detail-container .graph-container .history-select {
  text-align: center;
  font-size: 120%;
}

.sensor-detail-container .graph-container .history-select a {
  cursor: pointer;
  padding-left: 20px;
  padding-right: 20px;
  text-decoration: underline;
}

.sensor-detail-container .graph-container .history-select .selected {
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .sensor-detail-container .top-containers, .sensor-detail-container .bottom-containers, .sensor-detail-container .battery-container {
    flex-direction: column;
    justify-content: center;
  }

  .sensor-detail-container .bottom-right-container {
    margin: 0 auto;
  }

  .sensor-detail-container .temp-box, .sensor-detail-container .wind-box, .sensor-detail-container .rain-box, .sensor-detail-container .humidity-box {
    justify-content: center;
  }
}

.sensor-detail-container .page-title-container {
  text-align: center;
  margin-bottom: 10px;
}

.sensor-detail-container .page-title-container h1 {
  margin-bottom: 0;
}

.sensor-detail-container .temp-box {
  flex-flow: row;
  display: flex;
}

.sensor-detail-container .temp-box .current-temp {
  vertical-align: text-top;
  margin-right: 10px;
  font-size: 80px;
  display: flex;
}

.sensor-detail-container .temp-box .current-temp .temp-type {
  margin-top: 10px;
  font-size: 16px;
}

.sensor-detail-container .temp-box .high-low-temps {
  flex-direction: column;
  justify-content: space-around;
  display: flex;
}

.sensor-detail-container .feels-like-container {
  justify-content: space-around;
  display: flex;
}

.sensor-detail-container .high-low-icon {
  font-size: 80%;
}

.sensor-detail-container .wind-box {
  padding-top: 10px;
  display: flex;
}

.sensor-detail-container .wind-box .wind-icon {
  filter: invert(91%) sepia(1%) saturate(0%) hue-rotate(138deg) brightness(95%) contrast(82%);
  height: 85px;
}

.sensor-detail-container .wind-box .wind-dir {
  padding: 10px 20px;
  font-size: 72px;
}

.sensor-detail-container .wind-box .wind-speeds {
  flex-direction: column;
  justify-content: space-between;
  height: 103px;
  display: flex;
}

.sensor-detail-container .wind-box .wind-speeds .wind-speed-row {
  display: flex;
}

.sensor-detail-container .wind-box .wind-speeds .wind-speed-row .high-low {
  padding-left: 5px;
}

.sensor-detail-container .humidity-box {
  display: flex;
}

.sensor-detail-container .humidity-box .current-humidity {
  padding-right: 10px;
  font-size: 300%;
}

.sensor-detail-container .humidity-box .high-low-container {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.sensor-detail-container .humidity-box .high-low-container .high {
  padding-top: 6px;
}

.sensor-detail-container .humidity-box .high-low-container .low {
  padding-bottom: 6px;
}

.sensor-detail-container .rain-box .total-rain {
  padding-left: 10px;
  font-size: 300%;
}

.faq-container {
  max-width: 800px;
  margin: 0 auto;
  padding-left: 40px;
  padding-right: 40px;
}

.faq-container .faq-item-container {
  border-bottom: 1px solid #eee;
}

.faq-container .faq-item-container .faq-item-content {
  margin-left: 10px;
}

.faq-container .faq-item-container .faq-item-content .ambient-screenshot {
  text-align: center;
}

.faq-container .faq-item-container:last-child {
  border-bottom: none;
}

.toggle-checkbox input[type="checkbox"] {
  visibility: hidden;
  width: 0;
  height: 0;
}

.toggle-checkbox label {
  cursor: pointer;
  display: block;
  position: relative;
}

.toggle-checkbox label img {
  filter: invert(91%) sepia(1%) saturate(0%) hue-rotate(138deg) brightness(95%) contrast(82%);
  width: 30px;
}

.toggle-checkbox label img.selected {
  filter: invert(77%) sepia(26%) saturate(7157%) hue-rotate(160deg) brightness(102%) contrast(101%);
}

.map-container {
  width: 100vw;
  height: calc(100vh - 30px);
  margin-top: 10px;
}

.sensor-popup {
  width: 500px;
}

.sensor-popup .sensor-list-item {
  width: 430px;
}

.sensor-popup .sensor-list-item .sensor-information .sensor-title h2 a, .sensor-popup .sensor-list-item .sensor-information .sensor-title h2 a:visited {
  color: #1c53b0;
}

.leaflet-popup-close-button {
  margin-top: 5px;
  margin-right: 5px;
}

.leaflet-popup-close-button span {
  font-size: 25px;
}

.solar-radiation-container {
  font-size: 800%;
  font-weight: lighter;
}

.privacy-container {
  max-width: 800px;
  margin: 0 auto;
  padding-left: 40px;
  padding-right: 40px;
}

.leaflet-pane, .leaflet-tile, .leaflet-marker-icon, .leaflet-marker-shadow, .leaflet-tile-container, .leaflet-pane > svg, .leaflet-pane > canvas, .leaflet-zoom-box, .leaflet-image-layer, .leaflet-layer {
  position: absolute;
  top: 0;
  left: 0;
}

.leaflet-container {
  overflow: hidden;
}

.leaflet-tile, .leaflet-marker-icon, .leaflet-marker-shadow {
  -webkit-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

.leaflet-tile::selection {
  background: none;
}

.leaflet-safari .leaflet-tile {
  image-rendering: -webkit-optimize-contrast;
}

.leaflet-safari .leaflet-tile-container {
  -webkit-transform-origin: 0 0;
  width: 1600px;
  height: 1600px;
}

.leaflet-marker-icon, .leaflet-marker-shadow {
  display: block;
}

.leaflet-container .leaflet-overlay-pane svg {
  max-width: none !important;
  max-height: none !important;
}

.leaflet-container .leaflet-marker-pane img, .leaflet-container .leaflet-shadow-pane img, .leaflet-container .leaflet-tile-pane img, .leaflet-container img.leaflet-image-layer, .leaflet-container .leaflet-tile {
  width: auto;
  padding: 0;
  max-width: none !important;
  max-height: none !important;
}

.leaflet-container img.leaflet-tile {
  mix-blend-mode: plus-lighter;
}

.leaflet-container.leaflet-touch-zoom {
  -ms-touch-action: pan-x pan-y;
  touch-action: pan-x pan-y;
}

.leaflet-container.leaflet-touch-drag {
  -ms-touch-action: pinch-zoom;
  touch-action: none;
  touch-action: pinch-zoom;
}

.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  -ms-touch-action: none;
  touch-action: none;
}

.leaflet-container {
  -webkit-tap-highlight-color: transparent;
}

.leaflet-container a {
  -webkit-tap-highlight-color: #33b5e566;
}

.leaflet-tile {
  filter: inherit;
  visibility: hidden;
}

.leaflet-tile-loaded {
  visibility: inherit;
}

.leaflet-zoom-box {
  box-sizing: border-box;
  z-index: 800;
  width: 0;
  height: 0;
}

.leaflet-overlay-pane svg {
  -moz-user-select: none;
}

.leaflet-pane {
  z-index: 400;
}

.leaflet-tile-pane {
  z-index: 200;
}

.leaflet-overlay-pane {
  z-index: 400;
}

.leaflet-shadow-pane {
  z-index: 500;
}

.leaflet-marker-pane {
  z-index: 600;
}

.leaflet-tooltip-pane {
  z-index: 650;
}

.leaflet-popup-pane {
  z-index: 700;
}

.leaflet-map-pane canvas {
  z-index: 100;
}

.leaflet-map-pane svg {
  z-index: 200;
}

.leaflet-vml-shape {
  width: 1px;
  height: 1px;
}

.lvml {
  behavior: url("#default#VML");
  display: inline-block;
  position: absolute;
}

.leaflet-control {
  z-index: 800;
  pointer-events: visiblePainted;
  pointer-events: auto;
  position: relative;
}

.leaflet-top, .leaflet-bottom {
  z-index: 1000;
  pointer-events: none;
  position: absolute;
}

.leaflet-top {
  top: 0;
}

.leaflet-right {
  right: 0;
}

.leaflet-bottom {
  bottom: 0;
}

.leaflet-left {
  left: 0;
}

.leaflet-control {
  float: left;
  clear: both;
}

.leaflet-right .leaflet-control {
  float: right;
}

.leaflet-top .leaflet-control {
  margin-top: 10px;
}

.leaflet-bottom .leaflet-control {
  margin-bottom: 10px;
}

.leaflet-left .leaflet-control {
  margin-left: 10px;
}

.leaflet-right .leaflet-control {
  margin-right: 10px;
}

.leaflet-fade-anim .leaflet-popup {
  opacity: 0;
  transition: opacity .2s linear;
}

.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  opacity: 1;
}

.leaflet-zoom-animated {
  transform-origin: 0 0;
}

svg.leaflet-zoom-animated {
  will-change: transform;
}

.leaflet-zoom-anim .leaflet-zoom-animated {
  -webkit-transition: -webkit-transform .25s cubic-bezier(0, 0, .25, 1);
  -moz-transition: -moz-transform .25s cubic-bezier(0, 0, .25, 1);
  transition: transform .25s cubic-bezier(0, 0, .25, 1);
}

.leaflet-zoom-anim .leaflet-tile, .leaflet-pan-anim .leaflet-tile {
  transition: none;
}

.leaflet-zoom-anim .leaflet-zoom-hide {
  visibility: hidden;
}

.leaflet-interactive {
  cursor: pointer;
}

.leaflet-grab {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.leaflet-crosshair, .leaflet-crosshair .leaflet-interactive {
  cursor: crosshair;
}

.leaflet-popup-pane, .leaflet-control {
  cursor: auto;
}

.leaflet-dragging .leaflet-grab, .leaflet-dragging .leaflet-grab .leaflet-interactive, .leaflet-dragging .leaflet-marker-draggable {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

.leaflet-marker-icon, .leaflet-marker-shadow, .leaflet-image-layer, .leaflet-pane > svg path, .leaflet-tile-container {
  pointer-events: none;
}

.leaflet-marker-icon.leaflet-interactive, .leaflet-image-layer.leaflet-interactive, .leaflet-pane > svg path.leaflet-interactive, svg.leaflet-image-layer.leaflet-interactive path {
  pointer-events: visiblePainted;
  pointer-events: auto;
}

.leaflet-container {
  outline-offset: 1px;
  background: #ddd;
}

.leaflet-container a {
  color: #0078a8;
}

.leaflet-zoom-box {
  background: #ffffff80;
  border: 2px dotted #38f;
}

.leaflet-container {
  font-family: Helvetica Neue, Arial, Helvetica, sans-serif;
  font-size: .75rem;
  line-height: 1.5;
}

.leaflet-bar {
  border-radius: 4px;
  box-shadow: 0 1px 5px #000000a6;
}

.leaflet-bar a {
  text-align: center;
  color: #000;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  width: 26px;
  height: 26px;
  line-height: 26px;
  text-decoration: none;
  display: block;
}

.leaflet-bar a, .leaflet-control-layers-toggle {
  background-position: 50%;
  background-repeat: no-repeat;
  display: block;
}

.leaflet-bar a:hover, .leaflet-bar a:focus {
  background-color: #f4f4f4;
}

.leaflet-bar a:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.leaflet-bar a:last-child {
  border-bottom: none;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.leaflet-bar a.leaflet-disabled {
  cursor: default;
  color: #bbb;
  background-color: #f4f4f4;
}

.leaflet-touch .leaflet-bar a {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.leaflet-touch .leaflet-bar a:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.leaflet-touch .leaflet-bar a:last-child {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.leaflet-control-zoom-in, .leaflet-control-zoom-out {
  text-indent: 1px;
  font: bold 18px Lucida Console, Monaco, monospace;
}

.leaflet-touch .leaflet-control-zoom-in, .leaflet-touch .leaflet-control-zoom-out {
  font-size: 22px;
}

.leaflet-control-layers {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 5px #0006;
}

.leaflet-control-layers-toggle {
  background-image: url("layers.760a0456.png");
  width: 36px;
  height: 36px;
}

.leaflet-retina .leaflet-control-layers-toggle {
  background-image: url("layers-2x.b7b89169.png");
  background-size: 26px 26px;
}

.leaflet-touch .leaflet-control-layers-toggle {
  width: 44px;
  height: 44px;
}

.leaflet-control-layers .leaflet-control-layers-list, .leaflet-control-layers-expanded .leaflet-control-layers-toggle {
  display: none;
}

.leaflet-control-layers-expanded .leaflet-control-layers-list {
  display: block;
  position: relative;
}

.leaflet-control-layers-expanded {
  color: #333;
  background: #fff;
  padding: 6px 10px 6px 6px;
}

.leaflet-control-layers-scrollbar {
  padding-right: 5px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.leaflet-control-layers-selector {
  margin-top: 2px;
  position: relative;
  top: 1px;
}

.leaflet-control-layers label {
  font-size: 1.08333em;
  display: block;
}

.leaflet-control-layers-separator {
  border-top: 1px solid #ddd;
  height: 0;
  margin: 5px -10px 5px -6px;
}

.leaflet-default-icon-path {
  background-image: url("marker-icon.3f7d3721.png");
}

.leaflet-container .leaflet-control-attribution {
  background: #fffc;
  margin: 0;
}

.leaflet-control-attribution, .leaflet-control-scale-line {
  color: #333;
  padding: 0 5px;
  line-height: 1.4;
}

.leaflet-control-attribution a {
  text-decoration: none;
}

.leaflet-control-attribution a:hover, .leaflet-control-attribution a:focus {
  text-decoration: underline;
}

.leaflet-attribution-flag {
  width: 1em;
  height: .6669em;
  vertical-align: baseline !important;
  display: inline !important;
}

.leaflet-left .leaflet-control-scale {
  margin-left: 5px;
}

.leaflet-bottom .leaflet-control-scale {
  margin-bottom: 5px;
}

.leaflet-control-scale-line {
  white-space: nowrap;
  box-sizing: border-box;
  text-shadow: 1px 1px #fff;
  background: #fffc;
  border: 2px solid #777;
  border-top: none;
  padding: 2px 5px 1px;
  line-height: 1.1;
}

.leaflet-control-scale-line:not(:first-child) {
  border-top: 2px solid #777;
  border-bottom: none;
  margin-top: -2px;
}

.leaflet-control-scale-line:not(:first-child):not(:last-child) {
  border-bottom: 2px solid #777;
}

.leaflet-touch .leaflet-control-attribution, .leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
  box-shadow: none;
}

.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
  background-clip: padding-box;
  border: 2px solid #0003;
}

.leaflet-popup {
  text-align: center;
  margin-bottom: 20px;
  position: absolute;
}

.leaflet-popup-content-wrapper {
  text-align: left;
  border-radius: 12px;
  padding: 1px;
}

.leaflet-popup-content {
  min-height: 1px;
  margin: 13px 24px 13px 20px;
  font-size: 1.08333em;
  line-height: 1.3;
}

.leaflet-popup-content p {
  margin: 1.3em 0;
}

.leaflet-popup-tip-container {
  pointer-events: none;
  width: 40px;
  height: 20px;
  margin-top: -1px;
  margin-left: -20px;
  position: absolute;
  left: 50%;
  overflow: hidden;
}

.leaflet-popup-tip {
  pointer-events: auto;
  width: 17px;
  height: 17px;
  margin: -10px auto 0;
  padding: 1px;
  transform: rotate(45deg);
}

.leaflet-popup-content-wrapper, .leaflet-popup-tip {
  color: #333;
  background: #fff;
  box-shadow: 0 3px 14px #0006;
}

.leaflet-container a.leaflet-popup-close-button {
  text-align: center;
  color: #757575;
  background: none;
  border: none;
  width: 24px;
  height: 24px;
  font: 16px / 24px Tahoma, Verdana, sans-serif;
  text-decoration: none;
  position: absolute;
  top: 0;
  right: 0;
}

.leaflet-container a.leaflet-popup-close-button:hover, .leaflet-container a.leaflet-popup-close-button:focus {
  color: #585858;
}

.leaflet-popup-scrolled {
  overflow: auto;
}

.leaflet-oldie .leaflet-popup-content-wrapper {
  -ms-zoom: 1;
}

.leaflet-oldie .leaflet-popup-tip {
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
  filter: progid:DXImageTransform. Microsoft. Matrix(M11= .707107, M12= .707107, M21= -.707107, M22= .707107);
  width: 24px;
  margin: 0 auto;
}

.leaflet-oldie .leaflet-control-zoom, .leaflet-oldie .leaflet-control-layers, .leaflet-oldie .leaflet-popup-content-wrapper, .leaflet-oldie .leaflet-popup-tip {
  border: 1px solid #999;
}

.leaflet-div-icon {
  background: #fff;
  border: 1px solid #666;
}

.leaflet-tooltip {
  color: #222;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  padding: 6px;
  position: absolute;
  box-shadow: 0 1px 3px #0006;
}

.leaflet-tooltip.leaflet-interactive {
  cursor: pointer;
  pointer-events: auto;
}

.leaflet-tooltip-top:before, .leaflet-tooltip-bottom:before, .leaflet-tooltip-left:before, .leaflet-tooltip-right:before {
  pointer-events: none;
  content: "";
  background: none;
  border: 6px solid #0000;
  position: absolute;
}

.leaflet-tooltip-bottom {
  margin-top: 6px;
}

.leaflet-tooltip-top {
  margin-top: -6px;
}

.leaflet-tooltip-bottom:before, .leaflet-tooltip-top:before {
  margin-left: -6px;
  left: 50%;
}

.leaflet-tooltip-top:before {
  border-top-color: #fff;
  margin-bottom: -12px;
  bottom: 0;
}

.leaflet-tooltip-bottom:before {
  border-bottom-color: #fff;
  margin-top: -12px;
  margin-left: -6px;
  top: 0;
}

.leaflet-tooltip-left {
  margin-left: -6px;
}

.leaflet-tooltip-right {
  margin-left: 6px;
}

.leaflet-tooltip-left:before, .leaflet-tooltip-right:before {
  margin-top: -6px;
  top: 50%;
}

.leaflet-tooltip-left:before {
  border-left-color: #fff;
  margin-right: -12px;
  right: 0;
}

.leaflet-tooltip-right:before {
  border-right-color: #fff;
  margin-left: -12px;
  left: 0;
}

@media print {
  .leaflet-control {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}

/*# sourceMappingURL=index.1494659f.css.map */
