$tundora: #444;
$silver: #ccc;
$dodger-blue: #1fcbff;
$fun-blue: #1C53B0;

@font-face {
  font-family: “San Francisco”;
  font-weight: 400;
  src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-regular-webfont.woff 304");
}

html,
body {
  margin: 0;
  padding: 0;
  background-color: $tundora;
  color: $silver;
  font-family: "San Francisco", "Helvetica Neue", Helvetica, Arial, sans-serif;

  a {
    color: $silver;
  }
}

.header {
  width: 100%;
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 800px;
  margin: 0 auto;

  div {
    width: 33%;
    flex-grow: 1;
  }

  .header-title {
    span {
      margin-left: 10px;
    }

    h1 {
      font-size: 100%;
      margin: 0;
      padding-left: 10px;

      a {
        text-decoration: none;
      }

      a:hover {
        text-decoration: underline;
      }
    }

    text-align: left;
  }

  .header-link {
    text-align: center;
  }

  .header-extra {
    text-align: right;

    span {
      padding-right: 10px;
    }
  }

  a,
  a:visited {
    color: $silver;
    font-weight: bold;
  }
}

.weather-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.sensor-loading-container {
  text-align: center;
}

.sensor-reading-container {
  padding: 40px;
  padding-bottom: 10px;
  text-align: center;
  min-width: 200px;
  max-width: 300px;

  h2 {
    margin: 0;

    a {
      color: $silver;
      text-decoration: none;
    }
  }

  .temp {
    font-weight: bold;
    font-size: 300%;
  }

  .feelslike {
    font-size: 125%;
  }

  .humidity {
    font-size: 150%;
  }

  .dailyrain {
    font-size: 115%;
  }

  .lastupdate {
    font-size: 85%;
  }
}

.sensor-list-container {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

.sensor-list-item {
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin: 20px;

  .sensor-information {
    padding-right: 5px;

    .sensor-title {
      h2 {

        margin-bottom: 4px;
        margin-top: 0px;

        a,
        a:visited {
          color: $silver;
        }
      }
    }

    .sensor-details {
      font-size: 95%;
    }

    .sensor-owner {
      font-size: 95%;
    }
  }

  .sensor-reading {
    text-align: right;

    .temp {
      font-size: 28px;
      font-weight: bold;
    }

    .sensor-action {
      .toggle-checkbox {
        display: flex;
        justify-content: end;
        margin-top: 10px;
      }
    }
  }
}

.sensor-list-pagination {
  text-align: center;
  padding-bottom: 20px;
  line-height: 150%;
  ;
}


.sensor-detail-container {

  max-width: 1200px;
  margin: auto;

  .toggle-container {
    float: right;
    margin-right: 20px;
  }

  .top-containers {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .top-left-container {
      padding-right: 25px;
      margin: auto 0;
    }
  }

  .bottom-containers {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 10px;

    .bottom-left-container {
      margin: auto 0;
    }
  }

  .battery-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 10px;
    font-size: 16px;
  }

  .genai-container {
    max-width: 600px;
    text-align: center;
    margin: 0 auto;
    padding-top: 10px;
    padding-bottom: 14px;

    .genai-content {
      margin: 0 auto;
      max-width: 400px;
      font-weight: bold;
      font-size: 18px;
      white-space: pre-wrap;
    }
  }

  .graph-container {
    .history-select {
      text-align: center;

      font-size: 120%;

      a {
        cursor: pointer;
        text-decoration: underline;
        padding-left: 20px;
        padding-right: 20px;
      }

      .selected {
        font-weight: bold;
      }
    }
  }

  @media screen and (max-width: 768px) {

    .top-containers,
    .bottom-containers,
    .battery-container {
      flex-direction: column;
      justify-content: center;
    }

    .bottom-right-container {
      margin: 0 auto;
    }

    .temp-box,
    .wind-box,
    .rain-box,
    .humidity-box {
      justify-content: center;
    }

  }

  .page-title-container {
    text-align: center;
    margin-bottom: 10px;

    h1 {
      margin-bottom: 0px;
    }
  }

  .temp-box {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    .current-temp {
      font-size: 80px;
      display: flex;
      vertical-align: text-top;
      margin-right: 10px;

      .temp-type {
        font-size: 16px;
        margin-top: 10px;
      }
    }

    .high-low-temps {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }

  }

  .feels-like-container {
    display: flex;
    justify-content: space-around;
  }

  .high-low-icon {
    font-size: 80%;
  }

  .wind-box {
    display: flex;
    padding-top: 10px;

    .wind-icon {
      height: 85px;
      filter: invert(91%) sepia(1%) saturate(0%) hue-rotate(138deg) brightness(95%) contrast(82%);
    }

    .wind-dir {
      padding: 10px 20px;
      font-size: 72px;
    }

    .wind-speeds {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 103px;

      .wind-speed-row {
        display: flex;


        .high-low {
          padding-left: 5px;


        }
      }
    }
  }

  .humidity-box {
    display: flex;

    .current-humidity {
      font-size: 300%;
      padding-right: 10px;
    }

    .high-low-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .high {
        padding-top: 6px;
      }

      .low {
        padding-bottom: 6px;
      }
    }
  }

  .rain-box {
    .total-rain {
      font-size: 300%;
      padding-left: 10px;
    }
  }
}

.faq-container {
  padding-left: 40px;
  padding-right: 40px;
  max-width: 800px;
  margin: 0 auto;

  .faq-item-container {
    .faq-item-content {
      margin-left: 10px;

      .ambient-screenshot {
        text-align: center;
      }
    }

    border-bottom: 1px solid#eee;

    &:last-child {
      border-bottom: none;
    }
  }
}

.toggle-checkbox {
  input[type=checkbox] {
    height: 0;
    width: 0;
    visibility: hidden;
  }


  label {
    cursor: pointer;
    display: block;
    position: relative;

    img {
      width: 30px;
      filter: invert(91%) sepia(1%) saturate(0%) hue-rotate(138deg) brightness(95%) contrast(82%);

      &.selected {
        filter: invert(77%) sepia(26%) saturate(7157%) hue-rotate(160deg) brightness(102%) contrast(101%);
      }
    }
  }
}

.map-container {
  height: calc(100vh - 30px);
  width: 100vw;
  margin-top: 10px;
}

// Map popup styling
.sensor-popup {
  width: 500px;

  .sensor-list-item {
    width: 430px;

    .sensor-information {
      .sensor-title {
        h2 {

          a,
          a:visited {
            color: $fun-blue
          }
        }
      }
    }
  }
}

.leaflet-popup-close-button {
  margin-right: 5px;
  margin-top: 5px;

  span {
    font-size: 25px;
  }
}

.solar-radiation-container {
  font-size: 800%;
  font-weight: lighter;
}

.privacy-container {
  padding-left: 40px;
  padding-right: 40px;
  max-width: 800px;
  margin: 0 auto;
}